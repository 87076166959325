import React from 'react';
import './PrivacyDeclaration.css';
import logo from './neerlandia-urk.png';

function Default() {

    return (
        <div className="App">
            <div className="App-Container">
                <div className="Container">
                    <img alt='Logo' src={logo} style={{ width: 300, marginTop: 8 }} />

                    <h1>Privacyverklaring  Planningssysteem</h1>
                    <p>
                        Via de mobiele applicatie worden privacygevoelige gegevens oftewel persoonsgegevens verwerkt. Het planningssysteem acht een zorgvuldige omgang met persoonsgegevens van groot belang. Persoonlijke gegevens worden door ons dan ook zorgvuldig verwerkt en beveiligd.

                        Bij onze verwerking houden wij ons aan de eisen die de Wet Bescherming Persoonsgegevens stelt. Neerlandia is de verantwoordelijke voor de gegevensverwerking. Omdat onze verwerkingen binnen de wettelijke vrijstellingsregelingen vallen, zijn deze niet aangemeld bij het College Bescherming Persoonsgegevens. In deze privacyverklaring leggen wij uit welke persoonsgegevens wij verzamelen en gebruiken en met welk doel. Wij raden u aan deze zorgvuldig te lezen.

                        Deze privacyverklaring is voor het laatst aangepast op 03-12-2020
                </p>
                    <h2>Gebruik van persoonsgegevens </h2>
                    <p>
                        Door het gebruiken van onze dienst laat u bepaalde gegevens bij ons achter. Dat kunnen persoonsgegevens zijn. Wij bewaren en gebruiken uitsluitend de persoonsgegevens die rechtstreeks door u worden opgegeven of waarvan bij opgave duidelijk is dat ze aan ons worden verstrekt te verwerken.
                </p>
                    <h2> Verwerking planningsysteem  </h2>
                    <p>
                        Het planningsysteem van Neerlandia bestaat uit een mobiele applicatie en de backoffice. Met de mobiele app. kunnen de werknemers hun beschikbaarheid doorgeven, absentie doorgeven en ingeplande uren inzien. Met de backoffice kunnen een beperkt aantal gebruikers (productie)planningen maken, verwijderen, wijzigen en inzien.
                        Het planningsysteem van Neerlandia haalt de persoonsgegevens uit Nmbrs.
                        De naamgegevens van Nmbrs worden gesynchroniseerd met het planningssysteem van Neerlandia. Deze gegevens zijn nodig voor het maken van een planning. Met het privé emailadres wordt een account gemaakt voor het planningsysteem. Daarnaast worden verwerkt:
                        <br></br>
                        - NAW-gegevens: Voor- en achternaam
                        <br></br>
                        - Contactgegevens: E-mailadres
                        <br></br>
                        <br></br>

                    De verwerkingsgrondslag is: <b>Toestemming</b>
                        <br></br>
                    De duur van de verwerking: Maximaal 2 jaar na afloop arbeidscontract.
                </p>
                    <h2>Waar kan ik verdere informatie krijgen?</h2>
                    <p>
                        Als u verdere vragen heeft over ons gebruik van onze technieken, stuur ons dan een email op: info@neerlandia.com.
                </p>
                    <h2>Gegevens inzien, aanpassen of verwijderen </h2>
                    <p>
                        U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen. U kunt een verzoek tot inzage, correctie of verwijdering sturen naar info@neerlandia.com. Neerlandia zal zo snel mogelijk, maar binnen vier weken, op uw verzoek reageren.
                </p>
                    <h2>Beveiligen</h2>
                    <p>
                        Neerlandia neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. De website van Neerlandia maakt gebruik van een betrouwbaar SSL Certificaat om te borgen dat uw persoonsgegevens niet in verkeerde handen vallen.

                        Als u de indruk heeft dat uw gegevens niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, of indien u meer informatie wenst over de beveiliging van door Neerlandia verzamelde persoonsgegevens, neem dan contact met Neerlandia op via info@neerlandia.nl.

                        www.neerlandia.com is een website van Neerlandia. Neerlandia is als volgt te bereiken:
                    <br></br>
                    Postadres: Amsteldiep 2
                    <br></br>
                    Vestigingsadres: Amsteldiep 2
                    <br></br>
                    8321 WH Urk
                    <br></br>
                    Inschrijvingsnummer handelsregister Kamer van Koophandel: 39025312
                    <br></br>
                    E-mailadres: info@neerlandia.com
                </p>
                </div>
            </div>
        </div >
    );
}

export default Default;
