import React from 'react';
import iosBadge from './ios_badge.svg';
import logo from './neerlandia-urk.png';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function Default() {

    const getCode = () => {
        var request = require('request');
        var options = {
            'method': 'GET',
            'url': 'https://europe-west1-nu-planning-v3-test.cloudfunctions.net/api/code',
            'headers': {
            }
        };
        request(options, (error: string | undefined, response: any) => {
            if (error) {
                toast.error("💩 Licenties zijn op, stuur een mail naar salaris@neerlandia en vraag om nieuwe licenties.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })

                throw new Error(error)
            } else {
                const result = JSON.parse(response.body);
                window.location.href = `itms-apps://buy.itunes.apple.com/WebObjects/MZFinance.woa/wa/freeProductCodeWizard?code=${result.code}`;
            }
        });
    }

    return (
        <div>
            <header className="App-header">
                <img alt='Logo' src={logo} style={{ maxWidth: 300 }} />

                <p>
                    De personeelsapp is alleen beschikbaar in de App of Play store.
                </p>

                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#">
                    <img alt='Ontdek het in de Play Store' src={iosBadge} style={{ width: 150 }} onClick={() => getCode()} />
                </a>

                <a href='https://play.google.com/store/apps/details?id=com.neerlandia.NeerlandiaStaff&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                    <img alt='Ontdek het op Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/nl_badge_web_generic.png' style={{ width: 178 }} />
                </a>
            </header>
            <ToastContainer />
        </div>
    );
}

export default Default;

