import React from 'react';
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history"

import Default from "./Default";
import PrivacyDeclaration from "./PrivacyDeclaration";

import './App.css';

function App() {
    const history = createBrowserHistory()
    return (
        <div className="App">
            <Router history={history}>
                <Switch>
                    <Route exact path="/" ><Default></Default></Route>
                    <Route path="/privacy-declaration"> <PrivacyDeclaration></PrivacyDeclaration></Route>
                    <Route path="/privacy-verklaring"> <PrivacyDeclaration></PrivacyDeclaration> </Route>
                    <Route><Default></Default></Route>
                </Switch>
            </Router>
        </div >
    );
}

export default App;
